.SwitchWithLabelContainer {
  display: flex;
  align-items: center;
  width: 100%;

  .SwitchWithLabelSwitchContainer {
    display: inline-block;
    margin-right: 10px;
  }

  .SwitchWithLabelLabel {
    display: inline-block;
    margin: 0;
  }
}