.HealthDashboard {
  .EventSummaryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .EventSummaryBox {
      flex: 0 0 19%;
      border: 1px solid rgba(208, 208, 208, 0.3);
      padding-top: 15px;
      margin-bottom: 5px;

      & > p {
        text-align: center;
      }

      .EventCounts {
        .EventCount {
          width: 50%;
          text-align: center;
          display: inline-block;

          .Count {
            font-size: 18px;
            margin-bottom: 0;
            color: #717CF4;

            &[data-count="0"] {
              color: #d0d0d0;
            }
          }
        }
      }
    }
  }
}