.EditButton {
  cursor: pointer;
  background: #fff;
  height: fit-content;
  border-radius: 3px;
  padding: 6px 8px;
}

.Row.HeaderRow {
  border-top: none;
  font-weight: bold;
}

.Row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #ececec;

  .RowItem {
    display: inline-flex;
    padding: 0 5px;
    width: 12%;
    align-self: center;
  }

  .RowItem > * {
    margin: 0;
  }
}