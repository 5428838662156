.SettingRowContainer {
  .SettingHeader, .SettingRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: 17%;
      text-align: center;

      p {
        margin: 0px;
        display: inline-block;
      }
    }

    > div:first-child {
      text-align: left;
    }

    > div:last-child {
      text-align: right;

      > div {
        float: right;
      }
    }


  }

  .SettingHeader {
    p {
      padding: 5px 0;
      font-weight: bold;
    }
  }

  .SettingRow {
    padding: 5px 0;
  }
}

.SettingGlobalActions {
  display: inline-block;
  position: relative;

  .Button {
    border-radius: 4px;
    padding: 6px 12px;
    margin-left: 10px;
    float: right;
  }

  .Button.Enable {
    background: #7B7AED;
    color: #fff;
  }

  .Button.Disable {
    background: #dadada;
  }
}
