.SearchableDropdown {

  .SelectComponent {
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgb(166, 166, 166);
    color: #000;
    cursor: default;
    user-select: none;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .SelectDropdown {
    outline: none;

    .Dropdown {
      background: #fff;
      z-index: 1000;
      margin-top: -5px;

      input {
        width: 100%;
        border: 1px solid rgb(166, 166, 166);
        outline: none;
        padding: 5px;
        border-bottom: none;
      }

      .Options {
        border: 1px solid rgb(166, 166, 166);
        height: 100px;
        overflow: scroll;

        div {
          padding: 5px;

          &:hover {
            background: #717CF4;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }
}