
.app {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.divider-block {
  display: inline-block;
  border-top: 1px solid rgb(143, 117, 218);
  padding: 10px 0px 0px 0px;
}

.block-form select {
  margin-left: 10px;
}
