.EventDashboard {
  .CollapsedViewEventType {
    display: inline-block;
    text-align: center;

    p {
      font-size: 12px;
      margin-bottom: 2px;
    }

    .EventCount {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .EventTypesSummary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .EventType {
      display: inline-block;
      text-align: center;
      flex: 0 0 19%;
      border-radius: 3px;
      margin-top: 10px;
      padding: 10px;

      &:not(.Hidden) {
        border: 1px solid rgba(208, 208, 208, 0.3);
        cursor: pointer;
      }

      .LoadingStatistics {
        font-size: 10px;
        text-align: center;
        padding: 10px 0;
      }

      .Statistics {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        flex-wrap: wrap;

        .Statistic {
          display: inline-block;
          flex: 0 0 30%;

          &:first-child, &:nth-child(2) {
            flex: 0 0 49%;
          }

          .TimeFrame {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 0;
          }

          .EventCount {
            font-size: 18px;
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }
      }

      & > p {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}