.MappingStatus {
  position: relative;

  .MappingStatusHoverText {
    position: absolute;
    background: #4c4343;
    color: #fff;
    padding: 15px;
    bottom: 30px;
    text-align: left;
    width: 400px;
    border-radius: 3px;
    z-index: 100;

    .Messages {
      margin-top: 10px;

      p {
        display: block;
      }
    }

    p {
      display: block;
    }
  }
}

