@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.hidden {
  display: none;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

//
// Nunito fonts
//

@font-face {
  font-family: 'Nunito';
  src: url('~hyper/public/fonts/Nunito-Regular.eot?inline');
  src: local('Nunito Regular'), local('Nunito-Regular'), url('~hyper/public/fonts/Nunito-Regular.eot?inline#iefix') format('embedded-opentype'), url('~hyper/public/fonts/Nunito-Regular.woff?inline') format('woff'), url('~hyper/public/fonts/Nunito-Regular.ttf?inline') format('truetype'), url('~hyper/public/fonts/Nunito-Regular.svg?inline#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('~hyper/public/fonts/Nunito-SemiBold.eot');
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url('~hyper/public/fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'), url('~hyper/public/fonts/Nunito-SemiBold.woff') format('woff'), url('~hyper/public/fonts/Nunito-SemiBold.ttf') format('truetype'), url('~hyper/public/fonts/Nunito-SemiBold.svg?inline#Roboto') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('~hyper/public/fonts/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'), url('~hyper/public/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'), url('~hyper/public/fonts/Nunito-Bold.woff') format('woff'), url('~hyper/public/fonts/Nunito-Bold.ttf') format('truetype'), url('~hyper/public/fonts/Nunito-Bold.svg?inline#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}

.row {
  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  &.success {
    svg {
      color: $success;
    }
  }
}

#hole rect { // :not(:first-child) {
  fill: black;
}