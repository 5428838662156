.SwitchContainer {
  width: 40px;
  height: 20px;
  border: 1px solid #adadad;
  border-radius: 100px;
  cursor: pointer;
  background: linear-gradient(to right, #7B7AED 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: bottom right;
  transition: all .2s linear;

  .ToggleSlider {
    border: 1px solid #adadad;
    border-radius: 100px;
    width: 18px;
    height: 18px;
    background: #fff;
    transition: linear .2s;
  }
}

.SwitchContainer.enabled {
  background-position: bottom left;

  .ToggleSlider {
    margin-left: 20px;
  }
}