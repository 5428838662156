@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "~hyper/scss/config/default/variables";
@import "~hyper/scss/config/default/custom-variables";
@import "./node_modules/bootstrap/scss/bootstrap";


// 
// menu.scss
//

// Logo
.logo {
  display: block;
  // line-height: 80px;

  &.logo-lg {
    display: block;
    width: 50%;
    margin: 0 auto;
    // height: 50px;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-image: url('~src/images/gotham_works_logo.svg?stroke=#fff');
  }

  &.logo-sm {
    display: none;
    width: 90%;
    margin: 0 auto;
    // height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    // background-image: url('~src/images/circle_logo.svg?stroke=#fff');
  }
}

.mobile {
  .logo {
    &.logo-lg {
      width: 75%;
    }
  }
}

.left-side-menu {
  .logo {
    &.logo-lg {
      width: 75%;
    }
  }
}

.condensed {
  .logo {
    &.logo-sm {
      svg {
        height: 100%;
      }

      width: 50%;
      display: block;
      height: 35px;
    }
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: $leftbar-width;
  overflow: hidden;
  padding: 5px 20px 65px;
  min-height: 80vh;

  &.full-width {
    margin-left: 0 !important;
    padding: 0 0 60px 0;
  }
}

// Left Side-menu (Default dark)
.left-side-menu {
  width: $leftbar-width;
  z-index: 10;
  background: $bg-leftbar;
  bottom: 0;
  position: fixed;
  transition: all .2s ease-out;
  top: 0;
  box-shadow: $shadow;
}


// Side-nav
.side-nav {
  color: black;

  .side-nav-link {
    color: $menu-item;
    display: block;
    padding: 15px 30px;
    font-size: 0.9375rem;
    position: relative;
    transition: all 0.4s;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $menu-item-hover;
      text-decoration: none;
      background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, .10) 50%); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 25%, rgba(255, 255, 255, .10) 50%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }

    span {
      vertical-align: middle;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 3px;
      text-align: center;
      vertical-align: middle;
      width: 20px;
    }
  }

  .menu-arrow {
    transition: transform .15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: .75rem;
    transform: translate(0, 0);
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    &.mm-active {
      > a {
        .menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-bold;
  }

  .mm-active {
    > a {
      color: $menu-item-active !important;
    }
  }
}

// Enlarge menu
div.condensed {
  min-height: 1200px;

  &.wrapper {
    // Side menu
    .left-side-menu {
      position: absolute;
      padding-top: 0;
      width: 70px;
      z-index: 5;

      .slimScrollDiv,
      .slimscroll-menu {
        overflow: inherit !important;
        height: auto !important;
      }

      .slimScrollBar {
        visibility: hidden;
      }

      #logo {
        fill: $menu-item;
      }
    }

    // Help Box
    .help-box {
      display: none;
    }

    // Content Page
    .content-page {
      margin-left: 70px;
    }

    //Footer
    .footer {
      left: 70px;
    }
  }

  // Sidebar Menu
  .side-nav {
    .side-nav-title,
    .menu-arrow,
    .badge,
    .collapse.in,
    .mm-collapse {
      display: none !important;
    }

    .nav.collapse {
      height: inherit !important;
    }

    .side-nav-item {
      position: relative;
      white-space: nowrap;

      .side-nav-link {
        padding: 15px 20px;
        min-height: 56px;

        &:hover,
        &:active,
        &:focus {
          color: $menu-item-hover;
        }

        i {
          font-size: 1.125rem;
          margin-right: 20px;
        }

        span {
          display: none;
          padding-left: 10px;
        }
      }

      &:hover {
        .side-nav-link {
          position: relative;
          width: 260px;
          color: $white;
          background: $bg-leftbar;

          span {
            display: inline;
          }
        }

        > ul {
          display: block !important;
          left: 70px;
          position: absolute;
          width: 190px;
          height: auto !important;
          padding: 5px 0;
          z-index: 9999;
          background: $bg-leftbar;

          a {
            padding: 8px 20px;
            position: relative;
            width: 190px;

            &:hover {
              color: $menu-item-hover;
            }
          }

          ul {
            left: 190px;
            top: 0;
          }
        }
      }
    }
  }

  .logo {
    &.logo-lg {
      width: 50%;
      margin: 0 auto;
      // path {
      //   fill: white;
      // }
      // circle {
      //   fill: white;
      // }
      // rect {
      //   fill: black;
      //   // fill-opacity: 0;
      // }
    }

    span.logo-sm {
      display: block;
      line-height: 70px;
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    overflow-x: hidden;
  }
  .left-side-menu {
    box-shadow: $shadow;
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable {
    .left-side-menu {
      display: block;
    }
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 5px 10px 65px;
  }
  div.condensed {
    .left-side-menu {
      margin-left: -70px;
    }
  }
  .logo {
    span.logo-lg {
      display: block;
    }

    span.logo-sm {
      display: none;
    }
  }
}

/// Help-box
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: lighten($primary, 2.5%);

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

// Light
.help-box-light {
  background-color: $gray-100;
}

.metismenu {
  padding: 0;

  li {
    list-style: none;
  }

  ul {
    padding: 0;
  }

  .mm-collapse:not(.mm-show) {
    display: none;
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility;
  }
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  li {
    a {
      padding: 8px 20px 8px 68px;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all 0.4s;

      &:focus,
      &:hover {
        color: $menu-item-hover;
      }

      .menu-arrow {
        line-height: 1.3rem;
      }
    }

    &.active {
      > a {
        color: $menu-item-active;
      }
    }
  }
}

.button-menu-mobile {
  .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all .5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }

  span {
    height: 2px;
    width: 100%;
    background-color: rgba($white, 0.8);
    display: block;
    margin-bottom: 5px;
    transition: transform .5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}
