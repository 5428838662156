.Flex3ColSpread {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Flex3ColSpread > * {
  width: 31%;
  align-items: center;
  margin-bottom: 10px;
}

.Section {
  margin-bottom: 40px;

  .SectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .SectionTitle {
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
      margin: 0px;
    }
  }
}